//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import {Message} from "element-ui";
import {fetchContractDetail} from "@/api/userContract";

export default {
	name: 'Contract',
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.usersOpened = from.fullPath.includes('contracts')
		})
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
	},
	data() {
		return {
			usersOpened: null,
			contractDetail: {
				contract_no: '',
                images: []
			},
			dialogImageUrl: '',
			dialogVisible: false,
			url: 'https://www.livemotorsale.com/images/editor/%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%A1%E0%B8%97%E0%B8%B0%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%AA%E0%B8%B9%E0%B8%8D%E0%B8%AB%E0%B8%B2%E0%B8%A2.jpg',
			srcList: ['https://www.livemotorsale.com/images/editor/%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%A1%E0%B8%97%E0%B8%B0%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%AA%E0%B8%B9%E0%B8%8D%E0%B8%AB%E0%B8%B2%E0%B8%A2.jpg'],
		}
	},
	computed: {
		fields() {
			return [
				{key: 'key', label: this.contract_no, _style: 'width:150px'},
				{key: 'value', label: '', _style: 'width:150px;'}
			]
		},
		contractData() {
			const id = this.$route.params.id
			const user = this.contractDatas.find(contract => contract.contract_no == id)
			const userDetails = user ? Object.entries(user) : [['id', 'Not found']]
			return userDetails.map(([key, value]) => {
				return {key, value}
			})
		},
		visibleData() {
			return this.contractData.filter(param => param.key !== 'contract_no')
		},
		username() {
			return this.contractData.filter(param => param.key === 'contract_no')[0].value
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await this.getContractData()
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async getContractData() {
			const id = this.$route.params.id
			await fetchContractDetail({contractId: id}).then(response => {
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					this.contractDetail = data
					if (data.step_detail) {
						for (let item of data.step_detail) {
							if (item.status_id === 1) {
								item.icon = 'el-icon-close'
								item.type = 'danger'
							} else if (item.status_id === 2) {
								item.icon = 'el-icon-check'
								item.type = 'success'
							} else if (item.status_id === 4) {
								item.icon = 'el-icon-location'
								item.type = 'warning'
								item.appointmentDate = moment(item.appointment_date, 'YYYY-MM-DD').format('LL')
							} else {
								item.icon = 'el-icon-more'
								item.type = 'primary'
							}
						}
					}
					if (data.images) {
						this.srcList = []
						for (let image of data.images) {
							this.srcList.push(image.url)
						}
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url
			this.dialogVisible = true
		},
		goBack() {
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/user/contracts'})
		}
	}
}
